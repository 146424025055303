<template>
  <div class="container-fluid">
    <div class="row row-no-gutters">
      <div class="col-xs-2">
        <div class="row">
          <div class="col-12 p-1">

            <div class="container-fluid">
              <div class="row justify-content-start">
                <div class="col-md-2 mb-4">
                  <span class="p-fluid">
                    <Dropdown
                      v-model="tipoFiltroSelecionado"
                      :options="tiposFiltro"
                      optionLabel="name"
                      optionValue="value"
                      placeholder="Selecione o tipo da busca"
                    />
                  </span>
                </div>
                <div class="col-md-8 mb-4">
                  <span class="p-fluid">
                    <InputText
                      v-model="filtro"
                      placeholder="Informe um filtro para a pesquisa"
                      maxlength="100"
                    />
                 </span>
                </div>
                <div class="col-md-2 mb-4">
                  <span class="p-fluid">
                    <Button
                      label="Pesquisar"
                      class="p-button-primary"
                      @click="buscarUsuarios()"
                      icon="pi pi-search"
                    />
                 </span>
                </div>
              </div>
            </div>

            <DataTable paginator showGridlines ref="dt" class="container-fluid p-datatable-customers"
              responsiveLayout="scroll" dataKey="id" v-model:filters="filters" :loading="loading" :value="usuariosList"
              :rows="10" :rowHover="true">

              <template #header>
                <div class="flex flex-wrap align-items-center justify-content-between gap-2">
                  <span class="text-xl text-900 font-bold">Seleção Usuário</span>
                </div>
              </template>

              <template #loading>
                <div class="mt-6"> Carregando Usuários... </div>
              </template>

              <Column field="actionsButton" header="Ações" :style="{ width: '100px' }">
                <template #body="{ data }">
                  <div class="d-flex justify-content-center">
                    <Button type="button" icon="pi pi-check" title="Selecionar Usuário" @click="selecionarUsuario(data.id)"
                      class="p-button-grid p-button-sm" />
                  </div>
                </template>
              </Column>

              <Column field="documento" header="CPF/CNPJ" bodyStyle="text-align: left" :style="{ width: '300px' }">
                <template #body="{ data }">
                  {{ data.pessoa.numeroDocumentoJuridico }}
                </template>
              </Column>

              <Column field="nome" header="Nome" bodyStyle="text-align: left">
                <template #body="{ data }">
                  {{ data.pessoa.nomeGeral }}
                </template>
              </Column>

            </DataTable>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import { FilterMatchMode } from 'primevue/api';
import { LoginService } from "@/services";

export default {
  name: "GridExtratoCredito",

  data() {
    return {
      filters: this.initFilters(),
      loading: false,
      usuariosList: [],
      filtro: '',
      tipoFiltroSelecionado: 'C',
      tiposFiltro: [
        { value: "C", name: "CPF/CNPJ" },
        { value: "N", name: "Nome" }
      ]
    };
  },

  async created(){
    await this.initFilters();
  },

  async mounted() {
    await this.buscarUsuarios();
  },

  computed: {
    idCliente() {
      return this.$store.getters.getIdCliente;
    },
    currentUser() {
      return LoginService.currentUserValue;
    },
    currentUserAdmin() {
      return LoginService.currentUserAdminValue;
    },
    idUserAdmin() {
      return this.currentUserAdmin && this.currentUserAdmin.usuario ? this.currentUserAdmin.usuario.id : null;
    },
  },

  methods: {
    initFilters() {
      this.filters = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        documento: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        nome: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        numero: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
      };
    },

    processando(show) {
      this.loading = show || false;
      this.$store.dispatch("SET_ENABLE_SPINNER", this.loading);
    },

    alerta(type, msg) {
      this.$toast.add({
        severity: type,
        summary: msg,
        life: 3000,
      });
    },

    async buscarUsuarios() {
      try {
        this.processando(true);

        //Só busca usuários se tiver Admin logado.
        if (this.currentUserAdmin && this.currentUserAdmin.acessoMaster === 'S') {
          await LoginService.buscarUsuarioPorCliente(this.idCliente, this.tipoFiltroSelecionado, this.filtro).then(
            response => {
              this.usuariosList = response.data;
            }
          )
        }
      } catch (errorMessage) {
        console.log(errorMessage);
        this.alerta("error", "Houve um problema ao listar os Usuários!");
      } finally {
        this.processando(false);
      }
    },

    async selecionarUsuario(idUsuario) {
      await LoginService.buscarUsuarioSelecionadoLogin(this.idCliente, this.idUserAdmin, idUsuario).then(
        (data) => {
          console.log(data);
          this.$router.push('homeCredito');
        },
        (error) => {
          this.erro = "Usuário inválido!";
          if (typeof error.response.data === "string") {
            this.erro = error.response.data;
          }
          this.$toast.add({
          severity: "warn",
          summary: this.erro,
        });
        }
      );
      await this.$root.fetchFuncionalidades();
      await this.$root.atualizarDadosHeader();
    }
  },
};
</script>